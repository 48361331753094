define('m07/components/tex-t', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var x3dText = Ember['default'].Component.extend({
    tagName: 'transform',
    layoutName: 'tex-t',
    attributeBindings: ['translation', 'rotation', 'bboxcenter', 'bboxsize', 'center', 'scale', 'scaleorientation'],
    classNames: ['testClassNameXXX'],
    bboxcenter: "0 0 0",
    bboxsize: "-1,-1,-1",
    center: "0 0 0",
    scale: "1,1,1",
    scaleorientation: "0,0,0,0",

    austrittspunktRechts: false,

    translation: (function () {

      var name = this.get('name');
      var x3d = this.get('model').objectAt(0);
      var bauteile = x3d.get('bauteile');
      var typ = this.get('typ');

      var vwinkel = Number(x3d.get('vWinkel')) * Math.PI / 180;

      var x = 0;
      var y = 0;
      var z = 0;

      if (bauteile.findBy('id', name).get('typ') === 'box') {
        x = bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('x') / 20;
        y = bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('y') / 10;
        z = bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('z') / 20;
      }

      if (name === 'nebenTraeger') {
        x = x + 2;
      }

      var cords;
      // var skalierungsfaktor = 1;
      var skalierungsfaktor = x3d.get('skalierungsfaktor') + 0.6;

      var item = x3d.get('bauteile').findBy('id', name);

      var ueberstand = Number(x3d.get('ueberstand'));

      var hoeheMasskette = 0.5;
      var abstandVomBauteil = 0.15;
      var korrektur = 0.05;
      var abstandVonDerMasslinie = 0.05;

      switch (typ) {
        case 'breite':

          // if (x3d.get('ergebnisGeladen') === true && (name === 'mittelbauteil' || name === 'seitenbauteil0')){
          //   x = Number(item.get('punkt').objectAt(0).get('x'))/10;
          //   y = Number(item.get('punkt').objectAt(0).get('y'))/10;
          //   z = Number(item.get('punkt').objectAt(0).get('z'))/10 + Number(item.get('platte').objectAt(0).get('thickness'))/20;
          //
          //   }else{
          x = -Math.abs(x3d.get('xSeitenbauteil') / 10);
          if (name === 'nebenTraeger') {
            x = -Math.abs(x3d.get('xSeitenbauteil') / 10) - ueberstand;
          }
          z = 0;
          // }

          if (bauteile.findBy('id', 'viewpointOben').get('cameraProperties').objectAt(0).get('setBind')) {
            // if (x3d.get('ergebnisGeladen') === true){
            //   x = x -hoeheMasskette -abstandVonDerMasslinie;
            // }else{
            x = x - hoeheMasskette - abstandVonDerMasslinie;
            // }
          } else {
              x = x;
              y = y + hoeheMasskette + abstandVonDerMasslinie;
            }
          break;
        case 'hoehe':
          var richtungsvektor = 1;
          var delta = 0;

          // if (x3d.get('ergebnisGeladen') === true && (name === 'mittelbauteil' || name === 'seitenbauteil0')){
          //
          //   var level = 1;
          //
          //   if (name === 'mittelbauteil'){
          //     x = Number(item.get('punkt').objectAt(0).get('x'))/10;
          //     z = Number(item.get('punkt').objectAt(0).get('z'))/10;
          //     y = this.getMittelwert(Number(item.get('punkt').objectAt(0).get('y')), Number(item.get('punkt').objectAt(1).get('y')))/10;
          //     level = this.positionDurchSkalierung(1);
          //
          //     if(bauteile.findBy('id', 'viewpointLinks').get('cameraProperties').objectAt(0).get('setBind')){
          //
          //       if (x3d.get('zweiSchaerflaechen') === true){
          //         z = z - bauteile.findBy('id', 'seitenbauteil1').get('platte').objectAt(0).get('thickness')/10;
          //         if(x3d.get('verstaerkungBlass') === true && x3d.get('verstaerkungWerner') === false){
          //           z = z - (x3d.get('dickeBlass')/10);
          //         }else if(x3d.get('verstaerkungBlass') === true && x3d.get('verstaerkungWerner') === true){
          //           z = z - (x3d.get('dickeWerner')/10)*2;
          //         }
          //       }
          //
          //       z = z - 0.5 - 2 * this.positionDurchSkalierung(1);
          //     }else{
          //       x = x - 0.5 - 2 * this.positionDurchSkalierung(1);
          //     }
          //
          //   }else if(name === 'seitenbauteil0'){
          //
          //     var vwinkel = Number(x3d.get('vWinkel'))*Math.PI/180;
          //     var vwinkelScherkraft = Number(x3d.get('vWinkelScherkraft'))*Math.PI/180;
          //     x = Number(item.get('punkt').objectAt(2).get('x'))/10;
          //
          //     z = Number(item.get('punkt').objectAt(0).get('z'))/10 + Number(item.get('platte').objectAt(0).get('thickness'))/10;
          //
          //     if(bauteile.findBy('id', 'viewpointLinks').get('cameraProperties').objectAt(0).get('setBind')){
          //       z = (z + this.positionDurchSkalierung(-1));
          //     }else{
          //       x = x + this.positionDurchSkalierung(-1);
          //     }
          //
          //   }
          //
          // }else{

          if (name === 'nebenTraeger') {
            x = -Math.abs(x3d.get('xSeitenbauteil') / 10) - ueberstand;
            richtungsvektor = -1;
          } else if (name === 'hauptTraeger1') {
            var schraubenlaenge = Number(x3d.get('bauteile').findBy('id', "schraubeStandard1").get('screwProperties').objectAt(0).get('length')) / 10;
            var kopfPos = schraubenlaenge * Math.sin(0.7854);
            x = kopfPos + ueberstand;
          }

          y = y / 2;

          if (bauteile.findBy('id', 'viewpointLinks').get('cameraProperties').objectAt(0).get('setBind')) {
            if (x3d.get('zweiSchaerflaechen') === true && name === 'nebenTraeger') {
              z = z + Number(bauteile.findBy('id', 'hauptTraeger1').get('boxsizes').objectAt(0).get('z')) / 10;
            }
            z = richtungsvektor * (z + hoeheMasskette + abstandVonDerMasslinie);
          } else {
            x = x + richtungsvektor * (hoeheMasskette + abstandVonDerMasslinie);
            z = richtungsvektor * z;
          }
          // }

          break;
        case 'setztiefe':
          if (bauteile.findBy('id', 'viewpointOben').get('cameraProperties').objectAt(0).get('setBind')) {
            x = -Math.abs(x3d.get('xSeitenbauteil') / 10) - 2 * hoeheMasskette - abstandVomBauteil - abstandVonDerMasslinie;
            y = bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('y') / 10;
            z = bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('z') / 20 - bauteile.findBy('id', 'schraubeStandard1').get('screwProperties').objectAt(0).get('setztiefe') / 20;;
          } else {
            x = -Math.abs(x3d.get('xSeitenbauteil') / 10);
            y = -hoeheMasskette + abstandVonDerMasslinie;
            z = bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('z') / 20 - bauteile.findBy('id', 'schraubeStandard1').get('screwProperties').objectAt(0).get('setztiefe') / 20;
          }
          break;
        case 'lastenPfeil':

          var bauteilzusatz = 2;
          var textverschiebung = 0.5;

          var vwinkel = Number(x3d.get('vWinkel')) * Math.PI / 180;
          var vwinkelScherkraft = Number(x3d.get('vWinkelScherkraft')) * Math.PI / 180;

          if (name === 'axialkraftPfeil') {
            if (bauteile.findBy('id', 'viewpointLinks').get('cameraProperties').objectAt(0).get('setBind')) {
              x = 0;
              y = bauteile.findBy('id', 'hauptTraeger1').get('boxsizes').objectAt(0).get('y') / 20;
              z = bauteile.findBy('id', 'nebenTraeger').get('boxsizes').objectAt(0).get('z') / 20 + bauteile.findBy('id', 'hauptTraeger1').get('boxsizes').objectAt(0).get('z') / 10 + textverschiebung + (0.8 * skalierungsfaktor + 0.3 / skalierungsfaktor);
            } else {
              x = 0;
              y = bauteile.findBy('id', 'hauptTraeger1').get('boxsizes').objectAt(0).get('y') / 20;
              z = bauteile.findBy('id', 'nebenTraeger').get('boxsizes').objectAt(0).get('z') / 20 + bauteile.findBy('id', 'hauptTraeger1').get('boxsizes').objectAt(0).get('z') / 10 + 1.25;
            }
          } else if (name === 'scherkraftPfeil') {
            var yVerschiebung = x * Math.sin(vwinkel + vwinkelScherkraft);

            if (x3d.get('ergebnisGeladen') === true) {
              x = Number(bauteile.findBy('id', 'seitenbauteil0').get('punkt').objectAt(3).get('x') / 10) + textverschiebung + (0.6 * skalierungsfaktor - 0.14 / skalierungsfaktor);
              y = yVerschiebung;
            } else {
              x = bauteile.findBy('id', 'hauptTraeger1').get('boxsizes').objectAt(0).get('x') / 20 + bauteilzusatz + textverschiebung + (0.6 * skalierungsfaktor - 0.14 / skalierungsfaktor);
              y = bauteile.findBy('id', 'hauptTraeger1').get('boxsizes').objectAt(0).get('y') / 20 + yVerschiebung;
            }

            x = x * Math.cos(vwinkel + vwinkelScherkraft);
            z = bauteile.findBy('id', 'nebenTraeger').get('boxsizes').objectAt(0).get('z') / 20 + bauteile.findBy('id', 'hauptTraeger1').get('boxsizes').objectAt(0).get('z') / 20;
          }
          break;
        case 'vWinkel':

          var vwinkel = Number(x3d.get('vWinkel')) * Math.PI / 180;

          var l_endLinien = 0.5;
          var ueberstand = Number(x3d.get('ueberstand')) + 1 + Math.abs(Number(x3d.get('xSeitenbauteil'))) / 10;
          x = Number(bauteile.findBy('id', 'hauptTraeger1').get('boxsizes').objectAt(0).get('x')) / 20 + ueberstand + l_endLinien / 2;

          var yVerschiebung = x * Math.sin(vwinkel / 2);
          x = x * Math.cos(vwinkel / 2);
          y = yVerschiebung;
          z = 0;

          break;
        case 'einschraubwinkel':

          var einschraubwinkel = Number(bauteile.findBy('id', 'schraubeStandard1').get('rotations').objectAt(0).get('y'));

          x = 1.3;
          y = 0.9 * -Math.cos(einschraubwinkel / 2);
          z = 0;

          break;
      }

      // if (name === 'lastenPfeil'){
      //   y = (-2.5 -this.positionDurchSkalierung(0));
      // }

      cords = x + ' ' + y + ' ' + z;

      if (x3d.get('ergebnisGeladen') && name === typ) {

        var p1 = new Object();
        var p2 = new Object();
        var ansichtsvektor1 = new Object();
        var ansichtsvektor2 = new Object();
        var richtungsvektor1 = new Object();

        p1.x = item.get('punkt').objectAt(1).get('x') / 10;
        p1.y = item.get('punkt').objectAt(1).get('y') / 10;
        p1.z = item.get('punkt').objectAt(1).get('z') / 10;

        p2.x = item.get('punkt').objectAt(0).get('x') / 10;
        p2.y = item.get('punkt').objectAt(0).get('y') / 10;
        p2.z = item.get('punkt').objectAt(0).get('z') / 10;

        var sichtEbene = 'masskette' + this.getSichtEbene() + item.id.substring(0, item.id.length - 3);

        ansichtsvektor1.x = Number(item.get('ansichtsvektoren').findBy('id', sichtEbene).get('x'));
        ansichtsvektor1.y = Number(item.get('ansichtsvektoren').findBy('id', sichtEbene).get('y'));
        ansichtsvektor1.z = Number(item.get('ansichtsvektoren').findBy('id', sichtEbene).get('z'));

        richtungsvektor1.x = Number(item.get('richtungsvektor').objectAt(0).get('x'));
        richtungsvektor1.y = Number(item.get('richtungsvektor').objectAt(0).get('y'));
        richtungsvektor1.z = Number(item.get('richtungsvektor').objectAt(0).get('z'));

        ansichtsvektor2 = ansichtsvektor1;

        // var abstandMassketteText = 0.3;
        var abstandMassketteText = abstandVonDerMasslinie;
        if (ansichtsvektor1.y === -1) {
          // abstandMassketteText = (abstandMassketteText *2 + 0.1);
          abstandVonDerMasslinie = -abstandVonDerMasslinie;
        } else if (ansichtsvektor1.y === -1 && richtungsvektor1.z === 1) {
          // abstandMassketteText = (abstandMassketteText *2 + 0.1);
          abstandVonDerMasslinie = -abstandVonDerMasslinie;
        } else if (ansichtsvektor1.z === 1 && richtungsvektor1.x !== 0) {
          // abstandMassketteText = (abstandMassketteText *2 + 0.1);
          abstandVonDerMasslinie = -abstandVonDerMasslinie;
        }

        var ebene = Number(item.get('masskette').objectAt(0).get('ebene'));

        var lueckeZwischenMassketten = 0.2 * (ebene - 1);
        var b5 = 0.5;
        // var verschiebung = (b5*(ebene-1)+ lueckeZwischenMassketten);
        var verschiebung = hoeheMasskette + (ebene - 1) * hoeheMasskette + lueckeZwischenMassketten;

        cords = this.getXYZ(p1, p2, ansichtsvektor1, ansichtsvektor2, abstandVonDerMasslinie + verschiebung);
      }

      return cords;
    }).property('model.firstObject.transformHelper', 'model.firstObject.skalierungsfaktor', 'model.firstObject.schraubenlisteAktualisiert'),

    rotation: (function () {

      var name = this.get('name');
      var x3d = this.get('model').objectAt(0);
      var bauteile = x3d.get('bauteile');
      var typ = this.get('typ');
      var values;
      var x = 0,
          y = 0,
          z = 0,
          omega = 0;
      var w90Grad = 90 * Math.PI / 180;
      var w240Grad = 240 * Math.PI / 180;

      var item = bauteile.findBy('id', name);

      switch (typ) {
        case 'breite':
          if (bauteile.findBy('id', 'viewpointOben').get('cameraProperties').objectAt(0).get('setBind')) {
            x = 1;
            y = -1;
            z = -1;
            omega = 4.188;
          } else {
            x = 0;
            y = 1;
            z = 0;
            omega = -w90Grad;
          }
          break;
        case 'hoehe':
          var drehrichtung = 1;
          if (name === 'hauptTraeger1' || name === 'seitenbauteil0') {
            drehrichtung = -1;
          }

          if (bauteile.findBy('id', 'viewpointLinks').get('cameraProperties').objectAt(0).get('setBind')) {
            x = drehrichtung * 1;
            y = 1;
            z = drehrichtung * -1;
            omega = 4.1888;
          } else {
            x = 0;
            y = 0;
            z = 1;
            omega = drehrichtung * w90Grad;
          }
          break;
        case 'setztiefe':
          if (bauteile.findBy('id', 'viewpointOben').get('cameraProperties').objectAt(0).get('setBind')) {
            x = 1;
            y = -1;
            z = -1;
            omega = 4.1888;
          } else {
            x = 0;
            y = 1;
            z = 0;
            omega = -w90Grad;
          }
          break;
        case 'lastenPfeil':

          var vwinkel = Number(x3d.get('vWinkel')) * Math.PI / 180;
          var vwinkelScherkraft = Number(x3d.get('vWinkelScherkraft')) * Math.PI / 180;

          if (name === 'axialkraftPfeil') {
            if (bauteile.findBy('id', 'viewpointOben').get('cameraProperties').objectAt(0).get('setBind')) {
              x = 1;
              y = 0;
              z = 0;
              omega = -w90Grad;
            } else if (bauteile.findBy('id', 'viewpointLinks').get('cameraProperties').objectAt(0).get('setBind')) {
              x = 0;
              y = 1;
              z = 0;
              omega = -w90Grad;
            }
          } else if (name === 'scherkraftPfeil') {
            if (bauteile.findBy('id', 'viewpointOben').get('cameraProperties').objectAt(0).get('setBind')) {
              x = 1;
              y = 0;
              z = 0;
              omega = -w90Grad;
            }
          }
          break;
        case 'vWinkel':

          var vwinkel = Number(x3d.get('vWinkel')) * Math.PI / 180;
          x = 0;
          y = 0;
          z = 1;
          omega = -w90Grad + vwinkel / 2;

          break;
        case 'einschraubwinkel':

          x = 0;
          y = 0;
          z = 0;
          omega = -w90Grad;

          break;
      }

      if (x3d.get('ergebnisGeladen') && name === typ) {

        var sichtEbene = 'masskette' + this.getSichtEbene() + item.id.substring(0, item.id.length - 3);

        var ax = Number(item.get('ansichtsvektoren').findBy('id', sichtEbene).get('x'));
        var ay = Number(item.get('ansichtsvektoren').findBy('id', sichtEbene).get('y'));
        var az = Number(item.get('ansichtsvektoren').findBy('id', sichtEbene).get('z'));

        var rx = Number(item.get('richtungsvektor').objectAt(0).get('x'));
        var ry = Number(item.get('richtungsvektor').objectAt(0).get('y'));
        var rz = Number(item.get('richtungsvektor').objectAt(0).get('z'));

        if (rz !== 0) {
          rz = rz * rz / Math.abs(rz);
        }

        var kreuzprodukt = this.getKreuzprodukt({ x: 1, y: 0, z: 0 }, { x: rx, y: ry, z: rz });
        omega = this.getWinkel({ x: 1, y: 0, z: 0 }, { x: rx, y: ry, z: rz });

        x = kreuzprodukt.x;
        y = kreuzprodukt.y;
        z = kreuzprodukt.z;

        // console.log('sichtebene: '+this.getSichtEbene()+'  ::: name: '+name);

        if (ax > 0 && this.getSichtEbene() === "AnsichtXY" || az > 0 && (this.getSichtEbene() === "AnsichtYZ" || 0 < ry && ry < 1 && this.getSichtEbene() === "AnsichtXZ")) {
          // console.log('ax: '+ax+' > 0 || az: '+az+' > 0');
          omega = -(Math.PI - omega);
        }

        if (ax < 0 && this.getSichtEbene() === "AnsichtXZ" && ry === 0) {
          // console.log('ax: '+ax+' < 0');
          omega = -omega;
        }

        if (ax === 0 && this.getSichtEbene() === "AnsichtXZ" && ry === 1) {
          // console.log('ax: '+ax+' === 0');
          omega = -omega;
        }
      }

      if (name === 'lastenPfeil') {
        y = -2.5 - 0.3 * skalierungsfaktor + 0.3 / skalierungsfaktor;
      }

      values = x + ' ' + y + ' ' + z + ' ' + omega;

      if (name.substring(0, 8) === 'schraube') {

        if (this.get('typ') === 'xPos') {
          if (this.austrittspunktRechts) {
            values = '0 0 1 -1.5707';
          } else {
            if (bauteile.findBy('id', 'viewpointFront').get('cameraProperties').objectAt(0).get('setBind')) {
              values = '0 0 0 0';
            } else {
              values = '1 0 0 -1.5707';
            }
          }
        }

        if (this.get('typ') === 'zPos') {
          if (bauteile.findBy('id', 'viewpointLinks').get('cameraProperties').objectAt(0).get('setBind')) {
            values = '0 1 0 -1.5707';
          } else {
            values = '1 1 1 4.2';
          }
        }
      }

      return values;
    }).property('model.firstObject.transformHelper', 'model.firstObject.viewpointHelper'),

    wert: (function () {

      var name = this.get('name');
      var typ = this.get('typ');
      var x3d = this.get('model').objectAt(0);
      var bauteile = x3d.get('bauteile');
      var value = ' ';

      var item = x3d.get('bauteile').findBy('id', name);

      switch (typ) {
        case 'breite':
          if (x3d.get('ntBreiteEingetragen') && name === 'nebenTraeger') {
            value = Number(item.get('boxsizes').objectAt(0).get('z')).toFixed(1);
          } else if (x3d.get('htBreiteEingetragen') && name.substring(0, 12) === 'hauptTraeger') {
            value = Number(item.get('boxsizes').objectAt(0).get('z')).toFixed(1);
          } else if (name === 'mittelbauteil' || name === 'seitenbauteil0') {
            value = Number(item.get('platte').objectAt(0).get('thickness')).toFixed(1);
          }
          break;
        case 'hoehe':
          if (x3d.get('ntHoeheEingetragen') && name === 'nebenTraeger') {
            value = Number(item.get('boxsizes').objectAt(0).get('y')).toFixed(1);
          } else if (x3d.get('htHoeheEingetragen') && name.substring(0, 12) === 'hauptTraeger') {
            value = Number(item.get('boxsizes').objectAt(0).get('y')).toFixed(1);
          } else if (name === 'mittelbauteil' || name === 'seitenbauteil0') {
            value = Number(item.get('punkt').objectAt(0).get('y')) + Math.abs(Number(item.get('punkt').objectAt(1).get('y')));
            value = value.toFixed(1);
          }
          break;
        case 'setztiefe':
          if (x3d.get('setztiefeEingetragen')) {
            value = Number(bauteile.findBy('id', 'schraubeStandard1').get('screwProperties').objectAt(0).get('setztiefe')).toFixed(1);
          }
          break;
        case 'lastenPfeil':
          if (x3d.get('scherkraftEingetragen') && name === 'scherkraftPfeil') {
            value = Number(x3d.get('scherkraft')).toFixed(1);
            value = value + ' kN';
          } else if (x3d.get('axialkraftEingetragen') && name === 'axialkraftPfeil') {
            value = Number(x3d.get('axialkraft')).toFixed(1);
            value = value + ' kN';
          }
          break;
        case 'vWinkel':
          if (x3d.get('vWinkelEingetragen')) {
            value = Number(x3d.get('vWinkel')).toFixed(1);
          }
          break;
        case 'einschraubwinkel':
          if (x3d.get('einschraubwinkelEingetragen')) {
            value = String((Number(bauteile.findBy('id', 'schraubeStandard1').get('rotations').objectAt(0).get('y')) * 180 / Math.PI).toFixed(0)) + '°';
          }
          break;
      }

      if (name.substring(0, 8) === 'schraube' && x3d.get('ergebnisGeladen')) {
        if (this.get('typ') === 'xPos' && !bauteile.findBy('id', 'viewpointLinks').get('cameraProperties').objectAt(0).get('setBind')) {
          var xKopfPos = this.getSchraubeKopfPos(name, 'x');
          value = Math.abs((xKopfPos * 10).toFixed(1));
          value = parseFloat(value).toFixed(1);
        }

        if (this.get('typ') === 'zPos' && !bauteile.findBy('id', 'viewpointFront').get('cameraProperties').objectAt(0).get('setBind')) {
          switch (this.get('typ')) {
            case 'randAbstand1':
              value = x3d.get('randAbstand1');
              value = parseFloat(value).toFixed(1);
              break;
            case 'randAbstand2':
              value = x3d.get('randAbstand2');
              value = parseFloat(value).toFixed(1);
              break;
            case 'schraubenAbstand1':
              value = x3d.get('schraubenAbstand1');
              value = parseFloat(value).toFixed(1);
              break;
            case 'schraubenAbstand2':
              value = x3d.get('schraubenAbstand2');
              value = parseFloat(value).toFixed(1);
              break;
          }
        }
      }

      if (x3d.get('ergebnisGeladen') && name === typ && item.get('masskette').objectAt(0).get('masstext') !== "") {
        value = item.get('masskette').objectAt(0).get('masstext');
      }

      return String(value);
    }).property('model.firstObject.transformHelper'),

    diffusecolor: (function () {
      var name = this.get('name');
      var typ = this.get('typ');
      var x3d = this.get('model').objectAt(0);

      var emsvcolor = '0 0 0';

      if (x3d.get('ntbreiteAktiv') === true && name === 'nebenTraeger' && typ === 'breite') {
        emsvcolor = '1 0 0';
      }

      if (x3d.get('nthoeheAktiv') === true && name === 'nebenTraeger' && typ === 'hoehe') {
        emsvcolor = '1 0 0';
      }

      if (x3d.get('htbreiteAktiv') === true && name.substring(0, 12) === 'hauptTraeger' && typ === 'breite') {
        emsvcolor = '1 0 0';
      }

      if (x3d.get('hthoeheAktiv') === true && name === 'hauptTraeger1' && typ === 'hoehe') {
        emsvcolor = '1 0 0';
      }

      if (x3d.get('setztiefeAktiv') === true && name === 'hauptTraeger1' && typ === 'setztiefe') {
        emsvcolor = '1 0 0';
      }

      if (x3d.get('scherkraftAktiv') === true && name === 'scherkraftPfeil') {
        emsvcolor = '1 0 0';
      }

      if (x3d.get('axialkraftAktiv') === true && name === 'axialkraftPfeil') {
        emsvcolor = '1 0 0';
      }

      if (x3d.get('vWinkelAktiv') === true && typ === 'vWinkel') {
        emsvcolor = '1 0 0';
      }

      if (x3d.get('vWinkelScherkraftAktiv') === true && typ === 'vWinkelScherkraft') {
        emsvcolor = '1 0 0';
      }

      return String(emsvcolor);
    }).property('model.firstObject.htbreiteAktiv', 'model.firstObject.hthoeheAktiv', 'model.firstObject.ntbreiteAktiv', 'model.firstObject.nthoeheAktiv', 'model.firstObject.axialkraftAktiv', 'model.firstObject.scherkraftAktiv', 'model.firstObject.vWinkelAktiv', 'model.firstObject.vWinkelScherkraftAktiv', 'model.firstObject.setztiefeAktiv'),

    textgroesse: (function () {
      var skalierungsfaktor = this.get('model').objectAt(0).get('skalierungsfaktor');
      var value = 0.3 * skalierungsfaktor;
      return String(value);
    }).property('model.firstObject.skalierungsfaktor'),

    getSchraubeKopfPos: function getSchraubeKopfPos(name, achse) {
      var value = 0;

      var x3d = this.get('model');

      var item = x3d.objectAt(0).get('bauteile').findBy('id', name);

      var xVerschiebung = item.get('translations').objectAt(0).get('x') / 10;
      var yVerschiebung = item.get('translations').objectAt(0).get('y') / 10;
      var zVerschiebung = item.get('translations').objectAt(0).get('z') / 10;

      var schraubenlaenge = item.get('screwProperties').objectAt(0).get('length') / 10;
      var alpha = item.get('rotations').objectAt(0).get('z');
      var beta = item.get('rotations').objectAt(0).get('y');

      if (alpha > 1.5707 && !x3d.objectAt(0).get('schraubenGekreuzt')) {
        alpha = 1.5707 - (alpha - 1.5707);
        xVerschiebung = xVerschiebung * -1;
      }

      var delta = item.get('screwProperties').objectAt(0).get('headdiameter') / 20 * Math.cos(alpha);

      // var xKopfPos = -(Math.cos(beta)*yVerschiebung/Math.tan(alpha) - xVerschiebung);

      var xKopfPos;

      if (xVerschiebung > 0) {

        var vwinkel = x3d.objectAt(0).get('vWinkel');
        var xGesamt = yVerschiebung / Math.tan(alpha - 1.5707);
        var x2 = Math.abs(xGesamt) - xVerschiebung / Math.cos(beta);
        var gamma = Math.PI - (alpha - 1.5707) - vwinkel * Math.PI / 180;
        var a = x2 * Math.sin(alpha - 1.5707) / Math.sin(gamma);
        xKopfPos = Math.cos(vwinkel * Math.PI / 180) * a * -1;
        xKopfPos = Math.cos(beta) * xKopfPos;
      } else {
        xKopfPos = -(Math.cos(beta) * yVerschiebung / Math.tan(alpha) - xVerschiebung);
        if (Number(xKopfPos) > Number(x3d.objectAt(0).get('bauteile').findBy('id', 'nebenTraeger').get('boxsizes').objectAt(0).get('x') / 10)) {
          this.austrittspunktRechts = true;
          xKopfPos = (xKopfPos - x3d.objectAt(0).get('bauteile').findBy('id', 'nebenTraeger').get('boxsizes').objectAt(0).get('x') / 10) * Math.sin(alpha);
        }
      }

      var zKopfPos = Math.sin(beta) * (Math.cos(alpha) * schraubenlaenge) - zVerschiebung;

      if (achse === 'x') {
        value = xKopfPos;
      } else if (achse === 'z') {
        value = zKopfPos;
      }

      return value;
    },

    getMittelwert: function getMittelwert(a, b) {
      return (a + b) / 2;
    },

    positionDurchSkalierung: function positionDurchSkalierung(richtung) {
      var x3d = this.get('model').objectAt(0);
      var skalierungsfaktor = x3d.get('skalierungsfaktor') + 0.6;
      var value = 0.20 * skalierungsfaktor;
      return 0;
    },

    getXSeitenbauteilOben: function getXSeitenbauteilOben() {
      var name = this.get('name');

      var x3d = this.get('model').objectAt(0);
      var bauteile = x3d.get('bauteile');

      var hDiag = bauteile.findBy('id', 'hauptTraeger1').get('boxsizes').objectAt(0).get('y');
      var hGurt = bauteile.findBy('id', 'nebenTraeger').get('boxsizes').objectAt(0).get('y');;

      var alfa = parseFloat(x3d.get('vWinkel'));
      var beta = parseFloat(x3d.get('winkelEndAbschnittDiagonaleGRAD'));
      var ueDiagVert = parseFloat(x3d.get('ueberstandDiagonaleVertikal'));

      var value = 0;

      if (alfa !== 0 && alfa !== 90) {
        var exzentrizitaet = 0;

        if (x3d.get('ausmittigerstabanschluss') === true) {
          exzentrizitaet = x3d.get('exzentrizitaetDiagonale');
        } else {
          exzentrizitaet = Number(hDiag / 2 / Math.sin(alfa * Math.PI / 180)) + Number(hGurt / 2 / Math.tan(alfa * Math.PI / 180));

          if (Number(x3d.get('winkelEndAbschnittGRAD')) === 1 && Number(ueDiagVert) > hDiag * Math.cos(alfa * Math.PI / 180)) {
            exzentrizitaet = exzentrizitaet + (ueDiagVert - hDiag * Math.cos(alfa * Math.PI / 180)) / Math.tan(alfa * Math.PI / 180);
          } else if (Number(x3d.get('winkelEndAbschnittGRAD')) === 2) {
            exzentrizitaet = exzentrizitaet + ueDiagVert / Math.tan(alfa * Math.PI / 180);
          }
        }

        value = (exzentrizitaet / Math.cos(alfa * Math.PI / 180) - hDiag / 2 * Math.tan(alfa * Math.PI / 180)) / 10;
      } else {
        value = Math.abs(x3d.get('xSeitenbauteil') / 10 + Math.sin(alfa * Math.PI / 180) * ueDiagVert / 10);
      }
      return value;
    },

    scherkraftXWert: function scherkraftXWert(yGurt) {

      var ergebnis = 0;

      var x3d = this.get('model').objectAt(0);
      var bauteile = x3d.get('bauteile');

      var vWinkel = x3d.get('vWinkel');

      var P1 = bauteile.findBy('id', 'seitenbauteil0').get('punkt').objectAt(0);
      var P2 = bauteile.findBy('id', 'seitenbauteil0').get('punkt').objectAt(bauteile.findBy('id', 'seitenbauteil0').get('punkt').length - 1);
      var P3 = bauteile.findBy('id', 'seitenbauteil0').get('punkt').objectAt(bauteile.findBy('id', 'seitenbauteil0').get('punkt').length - 2);

      var x1 = P1.get('x');
      var y1 = P1.get('y');
      var x2 = P2.get('x');
      var y2 = P2.get('y');
      var x3 = P3.get('x');
      var y3 = P3.get('y');

      if (Number(vWinkel) === 0) {
        var Pmittelbauteil4 = bauteile.findBy('id', 'mittelbauteil').get('punkt').objectAt(bauteile.findBy('id', 'mittelbauteil').get('punkt').length - 1);
        var xmb4 = Pmittelbauteil4.get('x');
        ergebnis = x1 + (xmb4 - x1) / 2;
      } else if (Number(vWinkel) === 90) {
        ergebnis = x3 + (x2 - x3) / 2;
      } else {
        var dx = (x2 - x3) / 2;
        var dy = (y2 - y3) / 2;

        var m = (y2 - y1) / (x2 - x1);

        var n = y3 + dy - m * (x3 + dx);

        ergebnis = (yGurt * 10 - n) / m;
      }

      return ergebnis;
    },

    scherkraftYWert: function scherkraftYWert(x) {

      var y = 0;

      var x3d = this.get('model').objectAt(0);
      var bauteile = x3d.get('bauteile');

      var vWinkel = x3d.get('vWinkel');

      var P1 = bauteile.findBy('id', 'seitenbauteil0').get('punkt').objectAt(0);
      var P2 = bauteile.findBy('id', 'seitenbauteil0').get('punkt').objectAt(bauteile.findBy('id', 'seitenbauteil0').get('punkt').length - 1);
      var P3 = bauteile.findBy('id', 'seitenbauteil0').get('punkt').objectAt(bauteile.findBy('id', 'seitenbauteil0').get('punkt').length - 2);

      var x1 = P1.get('x');
      var y1 = P1.get('y');
      var x2 = P2.get('x');
      var y2 = P2.get('y');
      var x3 = P3.get('x');
      var y3 = P3.get('y');

      var dx = (x2 - x3) / 2;
      var dy = (y2 - y3) / 2;

      var m = (y2 - y1) / (x2 - x1);

      var n = y3 + dy - m * (x3 + dx);

      y = m * (10 * x) + n;

      return y;
    },

    vektorLaenge: function vektorLaenge(x, y, z) {
      return Math.sqrt(Math.pow(x, 2) + Math.pow(y, 2) + Math.pow(z, 2));
    },

    getXYZ: function getXYZ(punkt1, punkt2, r1, r2, richtungsAbstand) {

      var p1_2 = { x: Number(punkt1.x) + richtungsAbstand * Number(r1.x), y: Number(punkt1.y) + richtungsAbstand * Number(r1.y), z: Number(punkt1.z) + richtungsAbstand * Number(r1.z) };
      var p2_2 = { x: Number(punkt2.x) + richtungsAbstand * Number(r2.x), y: Number(punkt2.y) + richtungsAbstand * Number(r2.y), z: Number(punkt2.z) + richtungsAbstand * Number(r2.z) };

      var r3 = { x: p2_2.x - p1_2.x, y: p2_2.y - p1_2.y, z: p2_2.z - p1_2.z };
      var lr3 = this.vektorLaenge(r3.x, r3.y, r3.z);
      var r3Einheitsvektor = { x: r3.x / lr3, y: r3.y / lr3, z: r3.z / lr3 };

      var x = p1_2.x + r3Einheitsvektor.x * (lr3 / 2);
      var y = p1_2.y + r3Einheitsvektor.y * (lr3 / 2);
      var z = p1_2.z + r3Einheitsvektor.z * (lr3 / 2);

      return x + ' ' + y + ' ' + z;
    },

    getWinkel: function getWinkel(vektor, bezugsVektor) {
      var self = this;

      var xx = vektor.x * bezugsVektor.x;
      var yy = vektor.y * bezugsVektor.y;
      var zz = vektor.z * bezugsVektor.z;

      var l_vektor = self.vektorLaenge(vektor.x, vektor.y, vektor.z);
      var l_bezugsVektor = self.vektorLaenge(bezugsVektor.x, bezugsVektor.y, bezugsVektor.z);

      var winkel = Math.acos((xx + yy + zz) / (l_vektor * l_bezugsVektor));

      return winkel;
    },

    getKreuzprodukt: function getKreuzprodukt(vektor, bezugsVektor) {

      var x = vektor.y * bezugsVektor.z - vektor.z * bezugsVektor.y;
      var y = vektor.z * bezugsVektor.x - vektor.x * bezugsVektor.z;
      var z = vektor.x * bezugsVektor.y - vektor.y * bezugsVektor.x;

      return { x: x, y: y, z: z };
    },

    getSichtEbene: function getSichtEbene() {

      var name = this.get('name');
      var x3d = this.get('model').objectAt(0);
      var bauteile = x3d.get('bauteile');

      var item = x3d.get('bauteile').findBy('id', name);

      var sichtEbene = "AnsichtXY";
      if (bauteile.findBy('id', 'viewpointOben').get('cameraProperties').objectAt(0).get('setBind')) {
        sichtEbene = "AnsichtXZ";
      } else if (bauteile.findBy('id', 'viewpointLinks').get('cameraProperties').objectAt(0).get('setBind')) {
        sichtEbene = "AnsichtYZ";
      }

      return sichtEbene;
    },

    yRotation: (function () {

      var name = this.get('name');
      var x3d = this.get('model').objectAt(0);
      var bauteile = x3d.get('bauteile');
      var item = bauteile.findBy('id', name);

      var typ = this.get('typ');

      var x = 0;
      var y = 0;
      var z = 0;
      var beta = 0;

      if (x3d.get('ergebnisGeladen') && name === typ) {

        var sichtEbene = 'masskette' + this.getSichtEbene() + item.id.substring(0, item.id.length - 3);

        var ax = Number(item.get('ansichtsvektoren').findBy('id', sichtEbene).get('x'));
        var ay = Number(item.get('ansichtsvektoren').findBy('id', sichtEbene).get('y'));
        var az = Number(item.get('ansichtsvektoren').findBy('id', sichtEbene).get('z'));

        var rx = Number(item.get('richtungsvektor').objectAt(0).get('x'));
        var ry = Number(item.get('richtungsvektor').objectAt(0).get('y'));
        var rz = Number(item.get('richtungsvektor').objectAt(0).get('z'));

        if (rz !== 0) {
          rz = rz * rz / Math.abs(rz);
        }

        var drehachse = this.getKreuzprodukt({ x: 1, y: 0, z: 0 }, { x: rx, y: ry, z: rz });
        var drehwinkel = this.getWinkel({ x: 1, y: 0, z: 0 }, { x: rx, y: ry, z: rz });

        if (ax > 0 && this.getSichtEbene() === "AnsichtXY" || az > 0 && this.getSichtEbene() === "AnsichtYZ") {
          drehwinkel = -(Math.PI - drehwinkel);
        }

        var bezugsvektor = new Object();
        bezugsvektor.x = 0;
        bezugsvektor.y = 0;
        bezugsvektor.z = 0;

        bezugsvektor.x = drehachse.x * drehachse.y * (1 - Math.cos(drehwinkel)) - drehachse.z * Math.sin(drehwinkel);
        bezugsvektor.y = Math.pow(drehachse.y, 2) * (1 - Math.cos(drehwinkel)) + Math.cos(drehwinkel);
        bezugsvektor.z = drehachse.y * drehachse.z * (1 - Math.cos(drehwinkel)) + drehachse.x * Math.sin(drehwinkel);

        var kreuzprodukt = this.getKreuzprodukt({ x: ax, y: ay, z: az }, { x: bezugsvektor.x, y: bezugsvektor.y, z: bezugsvektor.z });

        beta = this.getWinkel({ x: bezugsvektor.x, y: bezugsvektor.y, z: bezugsvektor.z }, { x: ax, y: ay, z: az });

        // console.log('beta: '+beta);

        if ((az < 0 || az > 0 && rx > 0) && this.getSichtEbene() === "AnsichtYZ") {
          beta = -beta;
        }

        if (Math.abs(beta) === Math.PI) {
          beta = 0;
        }

        if (ax === 0 && this.getSichtEbene() === "AnsichtXZ" && rx === 1) {
          beta = -beta;
        }

        if (ax > 0 && this.getSichtEbene() === "AnsichtXZ") {
          beta = -beta;
        }

        if (ax < 0 && this.getSichtEbene() === "AnsichtXZ" && ry === 0) {
          beta = -beta;
        }

        if (az > 0 && rx < 0 && (this.getSichtEbene() === "AnsichtXZ" || this.getSichtEbene() === "AnsichtYZ")) {
          beta = -beta;
        }

        // console.log('yRotation x: 1 y: 0 z: 0 beta: '+beta);
        // console.log(' ');
      }

      var cords = 1 + ' ' + 0 + ' ' + 0 + ' ' + beta;

      return cords;
    }).property('model.firstObject.transformHelper', 'model.firstObject.schraubenlisteAktualisiert'),

    styleString: function styleString(value, laengenLabel, laengenFaktor, laengenNachkomma) {

      if (laengenLabel === 'mm' && (value.toString().indexOf('.') === -1 || Number((Number(value) * Number(laengenFaktor)).toString().substring(Number(value.toString().indexOf('.')) + 1, value.toString().length)) === 0)) {
        value = (Number(value) * Number(laengenFaktor)).toFixed(0);
      } else {
        value = (Number(value) * Number(laengenFaktor)).toFixed(Number(laengenNachkomma));
      }

      return value;
    }
  });

  exports['default'] = x3dText;

});